export const SET_USER = 'AUTH/SET_USER';
export const SET_TOKEN = 'AUTH/SET_TOKEN';
export const SET_REFRESH_TOKEN = 'AUTH/SET_REFRESH_TOKEN';

export const setUser = (user) => (dispatch) => {
  localStorage.setItem('user', JSON.stringify(user));
  dispatch({
    type: SET_USER,
    payload: user,
  });
};

export const setToken = (accessToken) => (dispatch) => {
  localStorage.setItem('access_token', accessToken);
  dispatch({
    type: SET_TOKEN,
    payload: accessToken,
  });
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem('refresh_token', refreshToken);
};
