import api from './api';

function withPaginationParams(url, startTime, endTime, page) {
  return `${url}${page ? `&page=${page}` : ''}${startTime ? `&start_time=${startTime}` : ''}${
    startTime ? `&end_time=${endTime}` : ''
  }`;
}

export function getPosts({ postType = null, startTime, endTime, page = 1 } = {}) {
  return api.get(withPaginationParams(`/posts/?post_type=${postType}`, startTime, endTime, page));
}

export function getPost(postId) {
  return api.get(`/posts/${postId}/`);
}

export function getPostSubscriptions({ startTime, endTime, page = 1 } = {}) {
  return api.get(withPaginationParams('/post-subscriptions/', startTime, endTime, page));
}

export function createPostSubscription(data) {
  return api.post('/post-subscriptions/', data);
}

export function getSlides({ startTime, endTime, page = 1 } = {}) {
  return api.get(withPaginationParams('/slides/?', startTime, endTime, page));
}

export function getSlide(slideId) {
  return api.get(`/slides/${slideId}/`);
}

export function getVideos({ startTime = null, endTime = null, page = 1 } = {}) {
  return api.get(withPaginationParams(`/videos/?`, startTime, endTime, page));
}

export function getVideo(videoId) {
  return api.get(`/videos/${videoId}/`);
}

export function getCities() {
  return api.get('/cities/');
}

export function getGoldPrice() {
  return api.get('/gold-price/');
}

export function getPrinciples() {
  return api.get('/principles/');
}

export function getQuestions({ startTime = null, endTime = null, page = 1 } = {}) {
  return api.get(withPaginationParams('/questions/', startTime, endTime, page));
}

export function createQuestion(data) {
  return api.post('/questions/', data);
}

export function getStores({ startTime, endTime, page = 1 } = {}) {
  return api.get(withPaginationParams('/stores/', startTime, endTime, page));
}

export function createStore(data) {
  return api.post('/stores/', data);
}

export function getStore(storeId) {
  return api.get(`/stores/${storeId}/`);
}

export function updateStore(storeId, data) {
  return api.put(`/stores/${storeId}/`, data);
}

export function deleteStore(storeId) {
  return api.delete(`/stores/${storeId}/`);
}

export function getWidgets() {
  return api.get('/widgets/');
}

export function createWidget(data) {
  return api.post('/widgets/', data);
}

export function getWidget(widgetId) {
  return api.get(`/widgets/${widgetId}/`);
}

export function getVolunteers({ startTime, endTime, page = 1 } = {}) {
  return api.get(withPaginationParams(`/volunteers/`, startTime, endTime, page));
}

export function createVolunteer(data) {
  return api.post('/volunteers/', data);
}

export function getVolunteer(volunteerId) {
  return api.get(`/volunteers/${volunteerId}/`);
}

export function getPayments({ startTime, endTime, page = 1 } = {}) {
  return api.get(withPaginationParams('/payments/', startTime, endTime, page));
}

export function createPayment(data) {
  return api.post('/payments/', data);
}

export function getPayment(paymentId) {
  return api.get(`/payments/${paymentId}/`);
}

export function updatePayment(paymentId, data) {
  return api.post(`/payments/${paymentId}/`, data);
}

export function deletePayment(paymentId) {
  return api.delete(`/payments/${paymentId}/`);
}

export function getStatistics() {
  return api.get('/statistics/');
}

export function getStatisticsItem(id) {
  return api.get(`/statistics/${id}`);
}

export function createVolunteerRequest(data) {
  return api.post('/volunteer-requests/', data);
}

export function getPrograms() {
  return api.get('/programs/');
}
