import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="210mm"
    height="297mm"
    data-name="Layer 1"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 21000 29700"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={6745.61}
        x2={9848.88}
        y1={14600.94}
        y2={12325.18}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#56cade",
          }}
        />
        <stop
          offset={0.671}
          style={{
            stopOpacity: 1,
            stopColor: "#a6d79f",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#f7e560",
          }}
        />
      </linearGradient>
    </defs>
    <path
      d="M8027.54 12351.25c-150.35-145.76-226.66-354.84-214.56-564.45 26.43-457.74 361.57-664.21 799.68-622.88 325.19 50.02 541.7 311.74 601.46 625.71 40.12 210.38-3.27 424.55-142.53 571.21-253.67 267.31-783.38 243.05-1044.05-9.59zm-2773.33-186.15c89.1 441.79 233.34 827.59 432.58 1157.26 13.15 21.73 33.06 58.85 56.79 86.33 29.32 33.88 57.53 47.49 96.72 20.68 37.51-25.74 23.79-74.61 12.54-110.8-25.49-82.17-84.78-195.14-110-250.45-119.71-262.46-188.02-461.01-231.44-693.66-59.02-316.18-197.92-1087.06 178.99-1221.13 161.65-57.47 349.26 35.2 392.19 397.13 8.25 173.23 70.95 499.93 152.38 747.27 3.63 11.03 13.44 39.55 25.98 73.15 16.94 45.43 35.89 120.42 78.93 153.12 15.4 11.69 33.94 17.96 56.54 15.57 62.43-6.68 61.3-98.81 44.8-198-32.97-198.25-72.25-489.15-.96-708.88 113.74-350.66 533.78-411.21 706.29-133.93 101.31 162.81 89.26 556.69 149.74 788.19 151.55 580.26 470.25 903.88 1043.36 963.61 613.86 63.44 1027.41-267.47 1240.4-992.82 47.96-163.21 65.72-370.67 120.18-514 67.62-178.12 205.17-267.11 412.58-266.81 224.35 6.98 355.94 151.69 394.8 433.93 124.38 851.21-194.38 1659.31-753.1 2319.84-416.93 492.97-1034.2 950.82-1691.9 1256.21-893.4 414.9-1922.03 588.79-2791.23 11.06-1081.28-718.61-1415.27-2279.01-1206.05-2817.52 43.7-112.45 112.26-171.79 183.67-178.53 50.05-4.68 89.65 9.27 121.36 34.76 51.9 41.8 88.22 135.3 118.34 236.42 104.36 351.07 199.54 638.48 441.24 928.22 35.2 42.32 99.33 121.36 158.54 75.1 12.18-9.57 18.81-22.66 21.28-36.85 7.18-41.06-19.3-80.88-38.41-115.53-226.41-410.71-431.26-867.96-466.33-1342.62-11.58-156.67-13.64-419.65 70.87-557.21 111.4-181.53 386.55-193.22 483.87 28.63 55.19 126.17 67.21 277.04 94.46 412.26z"
      style={{
        fill: "url(#a)",
      }}
    />
    <path
      d="m3045.85 18563.38-.05-4449.56c0 1294.88 3773.41 2778.11 3773.41 4449.56v-4449.5m1668.34 4449.56v-4449.5m4623.89 4449.5h-2955.53v-4449.59h2955.53m-683.63 2258.68h-2271.9m7784.03-2258.62H14131.7m1904.09 0v4449.58"
      style={{
        fill: "none",
        stroke: "#4a4a4a",
        strokeWidth: 491.14,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 22.9256,
      }}
    />
  </svg>
)
export default SvgComponent
