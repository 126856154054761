export const SET_SLIDES = 'UTILITY/SET_SLIDES';
export const SET_VIDEOS = 'UTILITY/SET_VIDEOS';
export const SET_NEWS = 'UTILITY/SET_NEWS';
export const SET_STORIES = 'UTILITY/SET_STORIES';
export const SET_REPORTS = 'UTILITY/SET_REPORTS';
export const SET_PRINCIPLES = 'UTILITY/SET_PRINCIPLES';
export const SET_CITIES = 'UTILITY/SET_CITIES';
export const SET_GOLD_PRICE = 'UTILITY/SET_GOLD_PRICE';
export const SET_SELECTED_CITY = 'UTILITY/SET_SELECTED_CITY';
export const SET_PROGRAMS = 'UTILITY/SET_PROGRAMS';

export const setSlides = (slides) => (dispatch) => {
  dispatch({
    type: SET_SLIDES,
    payload: slides,
  });
};

export const setVideos = (videos) => (dispatch) => {
  dispatch({
    type: SET_VIDEOS,
    payload: videos,
  });
};

export const setNews = (news) => (dispatch) => {
  dispatch({
    type: SET_NEWS,
    payload: news,
  });
};

export const setStories = (stories) => (dispatch) => {
  dispatch({
    type: SET_STORIES,
    payload: stories,
  });
};

export const setReports = (reports) => (dispatch) => {
  dispatch({
    type: SET_REPORTS,
    payload: reports,
  });
};

export const setPrinciples = (principles) => (dispatch) => {
  dispatch({
    type: SET_PRINCIPLES,
    payload: principles,
  });
};

export const setCities = (cities) => (dispatch) => {
  dispatch({
    type: SET_CITIES,
    payload: cities,
  });
};

export const setGoldPrice = (goldPrice) => (dispatch) => {
  dispatch({
    type: SET_GOLD_PRICE,
    payload: goldPrice,
  });
};

export const setSelectedCity = (city) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CITY,
    payload: city,
  });
};

export const setPrograms = (programs) => (dispatch) => {
  dispatch({
  type: SET_PROGRAMS,
  payload: programs,
  });
};
