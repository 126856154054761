import {
  SET_BENEFICIARIES,
  SET_SELECTED_BENEFICIARIES,
  SET_PROGRAMS,
  SET_SELECTED_PROGRAM,
  SET_DENOMINATIONS,
  SET_PAYMENTS_HISTORY,
  SET_ZAKYAT_CALCULATOR_FORM_VALUES,
  SET_ZAKYAT_CALCULATION_FORM_STATE,
  SET_ZAKYAT_CALCUATION_TRIGGERRER,
  SET_ZAKYAT_CALCULATOR_RESPONSE,
  SET_SELECTION_PARAMS,
  CREATE_BENEFICIARIES_AMOUNT,
  INIT_PAYMENT_PAYBOX,
} from '../actions/donations.actions';

const initialState = {
  beneficiaries: [],
  selectedBeneficiariesWithAmount: [],
  beneficiaryAmountIds: [],
  programs: [],
  selectedProgram: null,
  denominations: [],
  paymentsWithPagination: null,
  zakyatCalculatorFormValues: null,
  zakyatCalculatorFormState: {
    isValid: true,
    isRequestSent: false,
  },
  shouldCalculate: false,
  calculationResponse: null,
  selectionParams: null,
  initializedPayment: null,
};

const donationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BENEFICIARIES:
      return {
        ...state,
        beneficiaries: action.payload,
      };
    case SET_SELECTED_BENEFICIARIES:
      return {
        ...state,
        selectedBeneficiariesWithAmount: action.payload,
      };
    case SET_PROGRAMS:
      return {
        ...state,
        programs: action.payload,
      };
    case SET_SELECTED_PROGRAM:
      return {
        ...state,
        selectedProgram: action.payload,
      };
    case SET_DENOMINATIONS:
      return {
        ...state,
        denominations: action.payload,
      };
    case SET_PAYMENTS_HISTORY:
      return {
        ...state,
        paymentsWithPagination: action.payload,
      };
    case SET_ZAKYAT_CALCULATOR_FORM_VALUES:
      return {
        ...state,
        zakyatCalculatorFormValues: action.payload,
      };
    case SET_ZAKYAT_CALCULATION_FORM_STATE:
      return {
        ...state,
        zakyatCalculatorFormState: action.payload,
      };
    case SET_ZAKYAT_CALCUATION_TRIGGERRER:
      return {
        ...state,
        shouldCalculate: action.payload,
      };
    case SET_ZAKYAT_CALCULATOR_RESPONSE:
      return {
        ...state,
        calculationResponse: action.payload,
      };
    case SET_SELECTION_PARAMS:
      return {
        ...state,
        selectionParams: action.payload,
      };
    case CREATE_BENEFICIARIES_AMOUNT:
      return {
        ...state,
        beneficiaryAmountIds: action.payload,
      };
    case INIT_PAYMENT_PAYBOX:
      return {
        ...state,
        initializedPayment: action.payload,
      };
    default:
      return state;
  }
};

export default donationsReducer;
