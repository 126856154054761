import React from 'react';
import './LoadingPage.scss';

import spinnerSvg from 'assets/images/spinner.svg';

const LoadingPage = () => {
  return (
    <div className="LoadingPage">
      <img src={spinnerSvg} alt="spinner" />
    </div>
  );
};

export default LoadingPage;
