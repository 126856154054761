import axios from 'axios';

import { notification } from 'antd';

export const baseURL = process.env.REACT_APP_API;

let sessionExpired = false;
let isRefreshing = false;
let refreshSubscribers = [];

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const accessToken = localStorage.getItem('access_token');
if (accessToken) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

export function getHeader(name) {
  return instance.defaults.headers.common[name];
}

export function updateHeader(name, value) {
  if (value) {
    instance.defaults.headers.common[name] = value;
  } else {
    delete instance.defaults.headers.common[name];
  }
}

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
  refreshSubscribers = [];
}

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.code === 'token_not_valid' &&
      error.response.data.messages &&
      error.response.data.messages[0].token_type === 'access'
    ) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken && !originalRequest._retry) {
        originalRequest._retry = true;

        if (isRefreshing) {
          return new Promise((resolve) => {
            subscribeTokenRefresh((token) => {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              resolve(instance(originalRequest));
            });
          });
        }

        isRefreshing = true;
        return axios
          .post(`${baseURL}token/refresh/`, { refresh: refreshToken })
          .then((response) => {
            const { access } = response.data;
            localStorage.setItem('access_token', access);
            updateHeader('Authorization', `Bearer ${access}`);

            originalRequest.headers['Authorization'] = `Bearer ${access}`;

            onRefreshed(access);
            isRefreshing = false;
            return instance(originalRequest);
          })
          .catch((refreshError) => {
            handleSessionExpiry();
            return Promise.reject(refreshError);
          });
      } else {
        handleSessionExpiry();
      }
    }

    return Promise.reject(error);
  }
);

function handleSessionExpiry() {
  if (!sessionExpired) {
    sessionExpired = true;

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');

    notification.error({
      message: 'Ваша сессия закончилась',
      description: 'Авторизуйтесь для продолжения',
    });

    setTimeout(() => {
      window.location.href = '/auth/login';
    }, 3500);
  }
}

export default instance;
