import api from './api';
import { updateHeader } from './api';

function setTokens({ access, refresh }) {
  localStorage.setItem('access_token', access);
  localStorage.setItem('refresh_token', refresh);
  updateHeader('Authorization', `Bearer ${access}`);
}

export async function login({ email, password }) {
  try {
    const response = await api.post('/token/', { email, password });
    const { access, refresh } = response.data;
    setTokens({ access, refresh });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export function logout() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  updateHeader('Authorization', null);
  return Promise.resolve();
}

export async function refreshToken() {
  try {
    const refresh = localStorage.getItem('refresh_token');
    if (!refresh) throw new Error('No refresh token found');

    const response = await api.post('token/refresh/', { refresh });
    const { access } = response.data;
    localStorage.setItem('access_token', access);
    updateHeader('Authorization', `Bearer ${access}`);
    return Promise.resolve(response);
  } catch (error) {
    logout();
    return Promise.reject(error);
  }
}

export function getMyCredentials() {
  return api.get('/users/me/');
}

export function register(data) {
  updateHeader('Authorization', null);
  return api.post('/auth/register/', data);
}

export function forgotPassword(data) {
  return api.post('/auth/forgot-password/', data);
}

export function resetPassword(token, data) {
  return api.post(`/auth/password-reset/confirm/?token=${token}`, data);
}

export function updateUser(data) {
  return api.put(`/users/update_me/`, data);
}
