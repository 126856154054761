import {
  SET_SLIDES,
  SET_VIDEOS,
  SET_NEWS,
  SET_STORIES,
  SET_REPORTS,
  SET_PRINCIPLES,
  SET_CITIES,
  SET_GOLD_PRICE,
  SET_SELECTED_CITY,
  SET_PROGRAMS,
} from '../actions/utility.actions';

const initialState = {
  slides: [],
  videos: [],
  news: [],
  stories: [],
  reports: [],
  principles: [],
  cities: [],
  goldPrice: null,
  selectedCity: null,
  programs: [],
};

const utilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS:
      return {
        ...state,
        news: action.payload,
      };
    case SET_SLIDES:
      return {
        ...state,
        slides: action.payload,
      };
    case SET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
      };
    case SET_STORIES:
      return {
        ...state,
        stories: action.payload,
      };
    case SET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case SET_PRINCIPLES:
      return {
        ...state,
        principles: action.payload,
      };
    case SET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    case SET_GOLD_PRICE:
      return {
        ...state,
        goldPrice: action.payload,
      };
    case SET_SELECTED_CITY:
      return {
        ...state,
        selectedCity: action.payload,
      };
    case SET_PROGRAMS:
      return {
        ...state,
        programs: action.payload,
      };
    default:
      return state;
  }
};

export default utilityReducer;
