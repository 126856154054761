import { useLanguage } from 'app/language-context';

const useAsideMenu = () => {
  const { lang } = useLanguage();

  return [
    {
      title: lang.ASIDE.CHILDREN,
      path: '/profile/beneficiaries',
    },
    {
      title: lang.ASIDE.ABOUT_ZAKYAT,
      path: '/profile/zakyat/about',
      zakyatOnly: true,
    },
    {
      title: lang.ASIDE.PAY_ZAKYAT,
      path: '/profile/zakyat/pay',
      zakyatOnly: true,
    },
    {
      title: lang.ASIDE.ZAKYAT_CALCULATOR,
      path: '/profile/zakyat/calculator',
      zakyatOnly: true,
    },
    {
      title: lang.ASIDE.PAYMENT_HISTORY,
      path: '/profile/payment-history',
    },
    {
      title: lang.ASIDE.PROFILE,
      path: '/profile',
    },
    {
      title: lang.ASIDE.LOGOUT,
      path: '/auth/logout',
    },
  ];
};

export default useAsideMenu;
