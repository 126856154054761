export const getInfoLinks = (lang) => [
  {
    title: lang.HEADER.INFO_BLOCK.ABOUT_FUND,
    routerParams: {
      pathname: '/info/about-fund',
    },
    children: [
      {
        title: lang.HEADER.INFO_BLOCK.ABOUT_US,
        routerParams: {
          pathname: '/info/about-fund',
          hash: 'about-us',
        },
      },
      {
        title: lang.HEADER.INFO_BLOCK.OUR_NEWS,
        routerParams: {
          pathname: '/info/about-fund',
          hash: 'our-news',
        },
      },
    ],
  },
  {
    title: lang.HEADER.INFO_BLOCK.OUR_JOB,
    routerParams: {
      pathname: '/info/work',
    },
    children: [
      {
        title: lang.HEADER.INFO_BLOCK.VOLUNTEERS,
        routerParams: {
          pathname: '/info/work',
          hash: 'volunteering',
        },
      },
      {
        title: lang.HEADER.INFO_BLOCK.CHILDREN_HISTORIES,
        routerParams: {
          pathname: '/info/work',
          hash: 'history',
        },
      },
      {
        title: lang.HEADER.INFO_BLOCK.FUND_REPORTS,
        routerParams: {
          pathname: '/info/work',
          hash: 'reports',
        },
      },
    ],
  },
  {
    title: lang.HEADER.INFO_BLOCK.HOW_TO_HELP,
    routerParams: {
      pathname: '/info/how-to-help',
    },
    children: [
      {
        title: lang.HEADER.INFO_BLOCK.ABOUT_CHARITY,
        routerParams: {
          pathname: '/info/how-to-help',
          hash: 'about-charity',
        },
      },
      {
        title: lang.HEADER.INFO_BLOCK.HOW_IT_WORKS,
        routerParams: {
          pathname: '/info/how-to-help',
          hash: 'how-it-works',
        },
      },
    ],
  },
];
